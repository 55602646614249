// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hockey-player-of-the-week-questions-sample-js": () => import("./../src/pages/hockey/player-of-the-week-questions/sample.js" /* webpackChunkName: "component---src-pages-hockey-player-of-the-week-questions-sample-js" */),
  "component---src-pages-hockey-player-questions-sample-js": () => import("./../src/pages/hockey/player-questions/sample.js" /* webpackChunkName: "component---src-pages-hockey-player-questions-sample-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-song-danceability-index-js": () => import("./../src/pages/music/song-danceability/index.js" /* webpackChunkName: "component---src-pages-music-song-danceability-index-js" */),
  "component---src-pages-news-covid-19-js": () => import("./../src/pages/news/covid-19.js" /* webpackChunkName: "component---src-pages-news-covid-19-js" */),
  "component---src-pages-social-justice-homeless-shelter-occupancy-sample-js": () => import("./../src/pages/social-justice/homeless-shelter-occupancy/sample.js" /* webpackChunkName: "component---src-pages-social-justice-homeless-shelter-occupancy-sample-js" */)
}

